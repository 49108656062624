var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        { staticClass: "text-center mb-2" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "d-flex flex-column",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validate.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("v-text-field", {
                staticClass: "w-100 border",
                attrs: {
                  solo: "",
                  flat: "",
                  label: _vm.$t("Search..."),
                  "prepend-inner-icon": "mdi-magnify",
                  "hide-details": "",
                  rules: [(v) => !!v],
                  required: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.validate.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.followersError
        ? _c(
            "div",
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "d-flex align-items-center py-2 my-4 border",
                  attrs: { flat: "" },
                },
                [
                  _c(
                    "v-avatar",
                    { staticClass: "ms-3", attrs: { size: "96", rounded: "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          "lazy-src": require("@/assets/images/placeholder.png"),
                          src:
                            item.profilePicture != null
                              ? item.profilePicture
                              : require("@/assets/images/profile.jpg"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "f16 font-weight-bold ms-1" }, [
                    _vm._v(
                      _vm._s(item.firstName) + " " + _vm._s(item.lastName)
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "my-4" }, [
            _c("span", [_vm._v(_vm._s(_vm.followersErrorText))]),
          ]),
      !this.searchFlag
        ? _c("infinite-loading", { on: { infinite: _vm.infiniteHandler } }, [
            _c(
              "div",
              { attrs: { slot: "spinner" }, slot: "spinner" },
              [_c("v-icon", [_vm._v("mdi-selection-ellipse spin")])],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "no-more" }, slot: "no-more" },
              [_c("v-icon", [_vm._v("mdi-access-point-off")])],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "no-results" }, slot: "no-results" },
              [_c("v-icon", [_vm._v("mdi-access-point-off")])],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }